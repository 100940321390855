import React from "react"
import Container from "../../components/layout/Container"
import Layout from "../../components/layout/Layout"
import WideContainer from "../../components/layout/WideContainer"
import MetaData from "../../components/seo/MetaData"
import OrganizationStructuredData from "../../components/seo/OrganizationStructuredData"

interface Props {
  location: any
}

export default function RulesAndRegulationsPage({ location }: Props) {
  return (
    <Layout>
      <MetaData
        title="Rules and regulations in national parks"
        description="Get information on the rules of most national parks. There might still be a specific set of rules for certain parks."
        pageUrl={location.pathname}
      />
      <OrganizationStructuredData />
      <WideContainer center={true}>
        <div className={`container md:px-2 px-2`}>
          <h1 className="mt-6 md:mt-8 mb-8">
            Rules and regulations in national parks
          </h1>
        </div>
      </WideContainer>
      <Container center={true}>
        <p>
          All visitors to national parks are important guardians. You have a
          responsibility to act whenever you witness anything illegal within the
          park limits. Here is a list of activities that are mostly prohibited
          across national parks.
        </p>
        <h3>Prohibited activites</h3>
        <p className="mb-0 mt-0">- Feeding or approaching wildlife</p>
        <p className="mb-0 mt-0">- Hunting animals of any kind</p>
        <p className="mb-0 mt-0">- Collecting plants without permit</p>
        <p className="mb-0 mt-0">- Collecting reptiles/insects/butterflies</p>
        <p className="mb-0 mt-0">
          - Operating drones or other unmanned aircraft systems
        </p>
        <p className="mb-0 mt-0">- Camping outside the designated areas</p>
        <p className="mb-0 mt-0">- Driving vehicles outside designated areas</p>
        <p className="mb-0 mt-0">
          - Possessing and/or using illegal substances
        </p>
        <p className="mb-0 mt-0">- Using metal detectors</p>
        <p className="mb-0 mt-0">- Biking outside the designated areas</p>
        <p className="mb-0 mt-0">
          - Collecting archeological items (arrowheads etc.)
        </p>
        <p>
          Because national parks are located in separate countries, separate
          laws apply. The ones mentioned above are therefore mostly guidelines
          as rules vary. Be sure to investigate the local rules and regulations
          before entering a park.
        </p>
        <p>
          The rules and regulations are made to make sure that everyone gets to
          enjoy the park in a sustainable fashion. This will allow you to keep
          coming again and again. You can always contact the local visitor
          centers if you are in doubt about any rule.
        </p>
      </Container>
    </Layout>
  )
}
